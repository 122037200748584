<template>
  <div class="editor-wrapper">
    <vue-editor
      v-model="editorContent"
      useCustomImageHandler
      :style="cssVars"
      :editorOptions="editorSettings"
      :disabled="disabled"
      @image-added="handleImageAdded"
    />
  </div>
</template>

<script>
import {VueEditor, Quill} from 'vue2-editor';

import ImageResize from 'quill-image-resize-module';

Quill.register('modules/imageResize', ImageResize);

import {getEmailCampaign} from "@api/doinsport/services/campaign/email-campaign.api";
import {postClubMedia} from "@api/doinsport/services/medias/media.api";

export default {
  data: () => ({
    src: '',
    content: '',
    onDeep: true,
    editorSettings: {
      modules: {
        imageResize: {},
      },
    }
  }),
  props: {
    isEmpty: {
      type: Boolean,
      default: false
    },
    defaultContent: {
      type: String,
      default: this,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: String,
      default: '540px',
    },
  },
  computed: {
    cssVars() {
      if (this.disabled) {
        return {
          '--minHeight': this.customHeight,
          '--display': "none",
        }
      }
      return {
        '--minHeight': this.customHeight,
        '--display': "block",
      }
    },
    editorContent: {
      get() {
        return this.content
      },
      set(updatedContent) {
        this.$emit('on:editor-content:update', updatedContent)
      }
    },
  },
  components: {
    VueEditor
  },
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("name", file.name);
    formData.append('club', this.$store.getters["auth/currentClub"]['@id']);

    postClubMedia(formData)
      .then((result) => {
        const url = result.data.contentUrl;
        Editor.insertEmbed(cursorLocation, "image", this.$filePath(url));
        resetUploader();
      })
      .catch((error) => {
        console.error('error occured when uploading editor medias', error);
      })
    ;
  }
  },
  created() {
    if (this.isEmpty) {
      this.content = this.defaultContent;
    } else {
      if (this.$route.name === 'marketing_email_update' || this.$route.name === 'campaign_email_report') {
        getEmailCampaign(this.$route.params.id).then(
          (response) => {
            this.content = response.data.content;
          }
        )
        ;
      } else {
        const logo = this.$store.getters["auth/currentClub"].logo;

        if (logo) {
          if ("undefined" !== typeof logo.contentUrl && null !== logo.contentUrl) {
            this.src = this.$filePath(logo.contentUrl);
          }
        }
        if (this.src === '') {
          this.content = '<p class="ql-align-center"></p>';
        } else {
          this.content = '<p class="ql-align-center"><img width="300" class="img-fluid" height="150" src=' + this.src + '></p>';
        }
      }
    }
  }
};
</script>
<style lang="scss" src="@lazy/_editor.scss" scoped/>
